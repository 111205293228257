<template>
  <v-container>
    <v-row no-gutters>
      <v-card class="pa-2" elevation="5">
        <v-icon
          v-if="asset.LAST_SEEN == null || lastScan < startDate"
          color="primary"
          @click="SimulateScan"
          >mdi-checkbox-blank-outline</v-icon
        >
        <v-icon v-else color="success">mdi-checkbox-marked-outline</v-icon>
        {{ asset.MARCA }} {{ asset.DS_BENE }} - id: {{ asset.ID }}
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ScanAssetItem",

  props: {
    asset: Object,
    startDate: Date,
  },

  data: () => {
    return {
      lastScan: null,
    };
  },
  computed: {},
  created: function () {
    console.log(this.asset);
    if (this.asset.LAST_SEEN) {
      this.lastScan = new Date(this.asset.LAST_SEEN);
    }
  },

  methods: {
    // JUST FOR TESTING PURPOSE

    SimulateScan() {
      
      let header = {
        headers: {
          authorization: "Bearer " + this.$store.state.authJwt,
        },
      };

      this.$http
        .put( process.env.VUE_APP_PATRIMONIO_API + "/ScanAssets/" + "/updateLastSeen/" + this.asset.ID, null, header )
        .then((result) => {
          console.log(result);
          this.$emit('AssetUpdate', 'OK', this.asset, result);
          this.asset.LAST_SEEN = new Date(Date.now());
          this.lastScan = this.asset.LAST_SEEN;
        })
        .catch((err) => {
          console.log(err);
          this.$emit('AssetUpdate', 'FAIL', this.asset, err);
        });
    },
  },
};
</script>