<template>
  <v-card elevation="12" min-height="600">
    <v-card-title>Ricognizione</v-card-title>
    <v-row
      v-if="isScanAssetRunning && !isSelectingRoom && !isScanningAssets"
      justify="center"
      class="ma-2"
    >
      <v-btn color="primary" @click="SelectRoom">
        Seleziona la stanza per ricerca beni
      </v-btn>
    </v-row>
    <v-row v-if="isScanningAssets" justify="center" class="ma-2">
      <v-container>
        <v-row no-gutters>
          <v-col :cols="9" > 
            <v-card outlined tile >
              <ScanAssetItem v-for="a of roomAssets" :key="a.ID" :asset="a" :startDate="scanAssetStartDate" @click.native="SelectAsset(a)" @AssetUpdate="AssetUpdated">
              </ScanAssetItem>
            </v-card>
             </v-col>
          <v-col :cols="3" >
            <v-card outlined tile min-height="400" elevation="10" v-if="this.selectedAsset"> {{this.selectedAsset.ID}}</v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-btn color="error" @click="ExitRoom">
        Termina la ricognizione di questa stanza
      </v-btn>
    </v-row>
    <v-row
      v-if="isScanAssetRunning && !isSelectingRoom && !isScanningAssets"
      justify="center"
      class="ma-2"
    >
      <v-btn color="primary" @click="dialog = true">
        Concludi ricognizione
      </v-btn>
    </v-row>
    <v-row v-if="isSelectingRoom && !isScanningAssets">
      <RoomsDatatable @roomAssetFetch="RoomAssetFetched"> </RoomsDatatable>
    </v-row>
    <v-row justify="center">
      <v-snackbar
        v-model="permSnackbar"
        :color="snackbarColor"
        :timeout="timeout"
        :multi-line="true"
      >
        {{ errorText }}
        <template v-slot:action="{ attrs }">
          <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
    <v-row justify="center">
      <v-dialog class="ma-2" v-model="dialog" persistent max-width="400">
        <v-card>
          <v-card-text class="text-h5 pa-2">
            Sei sicuro di aver concluso tutte le stanze del dipartimento? Concludere la ricognizione comporterà la generazione dei report finali.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="ma-2" text @click="dialog = false">
              Annulla
            </v-btn>
            <v-btn color="primary" class="ma-2" text @click="CompleteScanAsset">
              CONFERMA
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
// @ is an alias to /src

import RoomsDatatable from '../../components/RoomsDatatable.vue';
import ScanAssetItem from '../../components/ScanAssetItem.vue';

export default {
  name: "Ricognizione",

  components: {
    RoomsDatatable,
    ScanAssetItem,
  },

  data: () => ({
    isScanAssetRunning: false,
    isSelectingRoom: false,
    isScanningAssets: false,

    scanAssetStartDate: null,
    roomAssets: null,
    selectedAsset: null,

    //// SNACKBAR DATA
    permSnackbar: false,
    snackbarColor: "success",
    errorText: "",
    timeout: -1,
    //////////////////
    dialog: false,
  }),

  methods: {
    SelectRoom() {
      this.isSelectingRoom = true;

      // TODO...
    },

    ExitRoom(){

      this.selectedAsset = null;
      this.isScanningAssets = false;
    },

    SelectAsset(asset){
      console.log(asset);
      this.selectedAsset = asset;
    },

    AssetUpdated(status, asset, result){

      if(status === 'OK'){
        this.permSnackbar = true;
        this.snackbarColor = "success";
        this.errorText = 'Asset: ' + asset.ID + ' -> ' + result.data.messageIT;
        this.timeout = 5000;
      }
      else {
        this.permSnackbar = true;
        this.snackbarColor = "error";
        this.errorText = 'Asset: ' + asset.ID + ' -> ' + result.data.messageIT;
        this.timeout = 5000;
      }
    },

    RoomAssetFetched(status, roomId, assets) {
      console.log(status);
      console.log(assets);

      this.roomAssets = assets;
      this.isSelectingRoom = false;

      if (status === "ERROR") {
        this.permSnackbar = true;
        this.snackbarColor = "error";
        this.errorText =
          "Impossibile recuperare gli asset della stanza: " + roomId;
        this.timeout = 5000;
      } else {
        this.isScanningAssets = true;
        this.permSnackbar = true;
        this.snackbarColor = "success";
        this.errorText = "Asset della stanza: " + roomId + " recuperati";
        this.timeout = 5000;
      }
    },

    CompleteScanAsset() {
      console.log("set the current scan asset process as completed");

      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API + "/scanAssets/completeScanAsset",
          null,
          { headers }
        )
        .then((response) => {
          console.log(response.data.scanAsset);
          if (response.status == 200) {
            this.permSnackbar = true;
            this.snackbarColor = "success";
            this.errorText =
              "Ricognizione completata :) -> contattare un admin per la generazione dei report.";
            this.timeout = -1;
            // If scanasset is running then hide the start button
            this.isScanAssetRunning = false;
            this.dialog = false;
          }
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
          this.permSnackbar = true;
          this.snackbarColor = "error";
          this.errorText = error.response.data;
          this.timeout = -1;

          this.FetchScanAssetState();
        });
    },

    FetchScanAssetState() {
      // Get the current state if any, from backend
      console.log("retrieving scan asset state...");

      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/scanAssets/state", {
          headers,
        })
        .then((response) => {
          console.log(response);
          if (response.data.found && response.data.state == "running") {
            // If scanasset is running then hide the start button
            this.isScanAssetRunning = true;
            this.scanAssetStartDate = new Date(response.data.startdate);
            //console.log(response.data.startdate);
          } else {
            console.log(response.data.found + " - " + response.data.state);
            console.log("No recognition running found");
            this.isScanAssetRunning = false;
          }
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
          // this.permSnackbar = true;
          // this.snackbarColor = "error";
          // this.errorText = error.response.data;
          // this.timeout= -1;
        });
    },
  },

  created: function () {
    // At creation check the surrent state on the backend db
    this.FetchScanAssetState();
    this.selectedAsset = null
  },

  beforeDestroy: function () {},
};
</script>